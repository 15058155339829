/* @import url('assets/fonts/NothingYouCouldDo-Regular.ttf'); */
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.5;
  margin-bottom: 2em;
  letter-spacing: 0.12em;
  word-spacing: 0.16em;
}

.fade-in .fadeOut {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s 1.5s,
    opacity 1.5s linear;
}

.matrix-modal-svg > *:focus {
  outline: 0 !important;
}

.pulsate {
  -ms-animation: pulsate 1.44s ease-in infinite;
  -webkit-animation: pulsate 1.44s ease-in infinite;
  -moz-animation: pulsate 1.44s ease-in infinite;
  -o-animation: pulsate 1.44s ease-in infinite;
  animation: pulsate 1.44s ease-in infinite;
}

@keyframes pulsate {
  0%,
  69%,
  100% {
    transform: scale(1);
  }
  83%,
  86% {
    transform: scale(1.1);
  }
}

.svg-wrapper svg {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
